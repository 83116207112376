import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApplicationDetailsApi } from "api/applicationDetails";
import { RootState } from "app/store";

export const GET_APPLICATION_DETAILS = 'GET_APPLICATION_DETAILS';

export type GetApplicationDetailsRequest = {
    uuid: string;
    fundType: string;
    applicationUuid: string;
};

// Application Details State
type ApplicationDetailsState = {
    success: boolean | null,
    loading: boolean,
    applicationDetails: ApplicationDetails,
    type: string
}

export type ApplicationDetails = {
    uuid: string;
    person_in_charge: string | null;
    date_created: string;
    status: string;
    read_status: number;
    type: string;
    specialist_schedule_label: string;
    specialist: Specialist
    company: Company;
    funds: Fund[];
    topics: {
        code: string;
        name: string;
    }[];
    meeting: Meeting;
    cancellation_reason: string | null;
    cancellation_date: string | null;
};

export type Specialist = {
    office_name: string;
    name: string;
    role: string;
    permission: string;
    available_service: string;
};

type Company = {
    name: string;
    address: string;
    industry: string;
    labor_regulations_compliant: number;
    allow_sharoushi: number;
    allow_shindanshi: number;
    insurances: Insurances[];
    person_in_charge: string;
    prefecture: string;
    employee_count_ranges: string;
    parttime_employee_count: number;
};

type Fund = {
    code: string;
    type: number;
    title: string;
    fund_requirements: FundRequirements[];
    application_remarks: string;
};

type FundRequirements = {
    title: string;
    requirements: {
        content: string;
    }[];
};

type Meeting = {
    zoom_id: string;
    join_url: string;
    daido_joins: number;
    password: string;
    emergency_contact_number: string | null;
    meeting_remarks: string;
    schedule: string;
    schedule_datetime: string;
    unimplemented_comments: string;
};

type Insurances = {
    code: string;
    name: string;
    created_at: string | null;
    updated_at: string | null;
    pivot: {
        company_dd: number;
        insurance_plan_dd: number;
    };
};

/**
 * GET Application details by uuid
 **/
export const getApplicationDetails = createAsyncThunk('/company/applications/details', async (data: GetApplicationDetailsRequest, { dispatch, rejectWithValue }) => {
    try {
        const response = await getApplicationDetailsApi(data);
        if (response.data.success === true) {
            dispatch(setApplicationDetails(response.data));
            return true;
        }
        return response.data;
    } catch (err) {
        return rejectWithValue(false);
    }
},
);

/**
 * Create Application Details Slice
 */
export const applicationDetailsSlice = createSlice({
    name: 'application',
    initialState: {
        success: false,
        loading: false,
        applicationDetails: {} as ApplicationDetails,
        type: '',
    } as ApplicationDetailsState,
    reducers: {
        reset: (state: ApplicationDetailsState) => {
            state.success = false;
            state.loading = true;
            state.applicationDetails = {} as ApplicationDetails;
            state.type = '';
        },
        setApplicationDetails: (state, { payload }) => {
            state.applicationDetails = payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getApplicationDetails.pending, (state: ApplicationDetailsState) => {
            state.type = GET_APPLICATION_DETAILS;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(getApplicationDetails.rejected, (state: ApplicationDetailsState) => {
            state.type = GET_APPLICATION_DETAILS;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getApplicationDetails.fulfilled, (state: ApplicationDetailsState) => {
            state.type = GET_APPLICATION_DETAILS;
            state.loading = false;
            state.success = false;
        });
    },
});

export const { setApplicationDetails } = applicationDetailsSlice.actions;
export const selectApplicationDetails = (state: RootState) => state.applicationDetails;