import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { applicationSlice } from 'app/slice/applicationSlice';
import { authSlice } from 'app/slice/authSlice';
import { companySlice } from 'app/slice/companySlice';
import { forgotPasswordSlice } from 'app/slice/forgotPasswordSlice';
import { fundApplicationSlice } from 'app/slice/fundApplicationSlice';
import { fundDetailsSlice } from 'app/slice/fundDetailsSlice';
import { fundRequirementsSlice } from 'app/slice/fundRequirementsSlice';
import { fundsListSlice } from 'app/slice/fundsListSlice';
import { inquireSlice } from 'app/slice/inquireSlice';
import { loaderSlice } from 'app/slice/loaderSlice';
import { loginSlice } from 'app/slice/loginSlice';
import { meetingSlice } from 'app/slice/meetingSlice';
import { notificationSlice } from 'app/slice/notificationSlice';
import { profileSlice } from 'app/slice/profileSlice';
import { registrationSlice } from 'app/slice/registrationSlice';
import { resourcesSlice } from 'app/slice/resourcesSlice';
import storage from 'redux-persist/lib/storage';
import { successModalSlice } from 'app/slice/successSlice';
import { policyApplicationListSlice } from 'app/slice/policyApplicationListSlice';
import { policySlice } from 'app/slice/policySlice';
import { policyApplicationDetailsSlice } from 'app/slice/policyApplicationDetailsSlice';
import { searchQuerySlice } from 'app/slice/searchQuerySlice';
import { userSlice } from './slice/userSlice';
import { generalMeetingSlice } from './slice/generalMeetingSlice';
import { policyDetailsSlice } from './slice/policyDetailsSlice';
import { applicationDetailsSlice } from './slice/applicationDetailsSlice';
import { userRegistrationSlice } from './slice/userRegistrationSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'],
    blacklist: ['auth'],
};

const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: ['email', 'name', 'uuid', 'token'],
};

const reducers = combineReducers({
    auth: persistReducer(authPersistConfig, authSlice.reducer),
    applications: applicationSlice.reducer,
    applicationDetails: applicationDetailsSlice.reducer,
    loader: loaderSlice.reducer,
    login: loginSlice.reducer,
    profile: profileSlice.reducer,
    successModal: successModalSlice.reducer,
    forgotPassword: forgotPasswordSlice.reducer,
    company: companySlice.reducer,
    registration: registrationSlice.reducer,
    resource: resourcesSlice.reducer,
    inquire: inquireSlice.reducer,
    fundsList: fundsListSlice.reducer,
    fundDetails: fundDetailsSlice.reducer,
    fundApplication: fundApplicationSlice.reducer,
    fundRequirements: fundRequirementsSlice.reducer,
    notification: notificationSlice.reducer,
    meeting: meetingSlice.reducer,
    policy: policySlice.reducer,
    policyDetails: policyDetailsSlice.reducer,
    policyApplicationDetails: policyApplicationDetailsSlice.reducer,
    policyApplications: policyApplicationListSlice.reducer,
    searchQuery: searchQuerySlice.reducer,
    user: userSlice.reducer,
    userRegistration: userRegistrationSlice.reducer,
    generalMeeting: generalMeetingSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
