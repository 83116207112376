import { MeetingCalendarParams, ScheduledSpecialistParams, SubmitPolicyScheduleRequest, SubmitScheduleRequest } from 'app/slice/meetingSlice';
import { getAxiosClientWithToken } from './index';
import { GeneralMeetingParam } from 'app/slice/generalMeetingSlice';

export const getScheduledMeetings = (data: MeetingCalendarParams) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/chukidan/scheduled/meetings', { params: data });
};

export const submitScheduleApi = (data: SubmitScheduleRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/specialist/application/' + data.uuid + '/schedule', {
        dates: data.dates,
        person_in_charge: data.personInCharge,
    });
};

export const submitPolicyScheduleApi = (data: SubmitPolicyScheduleRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/specialist/policies/applications/' + data.uuid + '/schedule', {
        dates: data.dates,
        person_in_charge: data.personInCharge,
    });
};

export const getScheduledHolidaysApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/chukidan/schedules/holidays');
};

export const generalMeetingApi = (params: GeneralMeetingParam) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/chukidan/applications`, { params: params });
};

export const generalMeetingStatusApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/chukidan/applications/statuses');
};

export const getScheduledSpecialistApi = (data: ScheduledSpecialistParams) => {
    const restClient = getAxiosClientWithToken();

    if (['sharoushi', 'shindanshi'].includes(data.role)) {
        return restClient.get('/chukidan/schedules', { params: {year_month: data.month} });
    }

    return restClient.get('/chukidan/' + data.role + '/schedule', { params: {year_month: data.month} });
}
