import React, { useCallback, useMemo } from 'react';
import { StyleList } from 'types';
import { Rule } from 'antd/lib/form';
import { useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';

type ValidateFile = {
    type: [string[], string];
    size: [number, string];
};

/**
 * Set body id for styling
 *
 * @param {string} style
 */
export const useStyle = (...style: StyleList[]) => {
    document.body.id = style.join(' ');
};

/**
 * Scroll to top
 */
export const scrollToTop = (behavior?: 'smooth' | 'auto') =>
    window.scroll({
        top: 0,
        left: 0,
        behavior: behavior,
    });

/**
 * Rule for checking password match
 *
 * @param {string} fieldName
 * @param {string} message
 * @returns {({getFieldValue}: {getFieldValue: any}) => {validator(_, value): (Promise<never>)}}
 */
export const validatePasswordConfirmation: (fieldName: string, message: string) => Rule = (fieldName, message) => {
    return ({ getFieldValue }) => ({
        validator(_, value) {
            if (value && getFieldValue(fieldName) !== value) {
                return Promise.reject(new Error(message));
            }
            return Promise.resolve();
        },
    });
};

/**
 * Rule for file checking
 *
 * @returns {({getFieldValue}: {getFieldValue: any}) => {validator(_, value): (Promise<never>)}}
 * @param rules
 */
export const validateFile: (rules: ValidateFile) => Rule = (rules: ValidateFile) => {
    return () => ({
        validator(_, value) {
            let { type: fileType, size: fileSize } = value.file;

            if (rules.type) {
                let [type, message] = rules.type;
                if (!type.includes(fileType)) {
                    return Promise.reject(new Error(message));
                }
            }

            if (rules.size) {
                let [size, message] = rules.size;
                if (size < fileSize) {
                    return Promise.reject(new Error(message));
                }
            }

            return Promise.resolve();
        },
    });
};

export const isDevMode = () => {
    return process.env.NODE_ENV === 'development' || window.location.hostname.includes('dev');
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const useParamsState = <T = string>(
    key: string,
    defaultValue: any = null,
    transform: (value: any) => any = (value) => value
): [T, (value: T) => void] => {
    const [searchParams, setSearchParams] = useSearchParams();

    return [
        useMemo(() => transform(searchParams.get(key) ?? defaultValue), [defaultValue, key, searchParams, transform]),
        useCallback(
            (value: T) => {
                const query = value as any;

                if (searchParams.get(key) !== query) {
                    setSearchParams({ ...Object.fromEntries(searchParams), [key]: query });
                }
            },
            [key, searchParams, setSearchParams]
        ),
    ];
};

/**
 * Change format of dates
 *
 * @returns {string}
 * @param rawDate
 */
// util/dateFormatter.js
export const formatDate = (rawDate: string | null): string => {
    if (!rawDate) return 'ー';
    return moment(rawDate).format('YYYY年MM月DD日');
};

export const formatTimeRange = (timeRange: string): string => {
    const [startTime, endTime] = timeRange.split(' ~ ').map(time => time.trim());
    return `${startTime}-${endTime}`;
};

/**
 * List of side menu items
 * Return breadcrumb title and url.
 */
export const generateBreadcrumbTitle = (uuid: string, kind: string) => {
    return {
        '/dashboard/meeting': ['トップ'],
        '/dashboard/user-management': ['トップ', 'ユーザー管理'],
        '/dashboard/user-management/user-registration': ['トップ', 'ユーザー管理', 'ユーザー登録'],
        [`/dashboard/user-management/${uuid}/details/${kind}`]: ['トップ', 'ユーザー管理', 'ユーザー詳細'],
        '/dashboard/josekin-meeting': ['トップ', '面談依頼一覧'],
        [`/dashboard/josekin-meeting/${uuid}/details`]: ['トップ', '面談依頼一覧', '面談詳細'],
        '/dashboard/hojokin-meeting': ['トップ', '面談依頼一覧'],
        [`/dashboard/hojokin-meeting/${uuid}/details`]: ['トップ', '面談依頼一覧', '面談詳細'],
        '/dashboard/wr/diagnose-management': ['トップ', '診断依頼一覧'],
        '/dashboard/wr/meeting-management': ['トップ', '面談依頼一覧'],
        [`/dashboard/wr/meeting-management/${uuid}/details`]: ['トップ', '面談依頼一覧', '面談詳細'],
        '/dashboard/profile': ['トップ', 'プロフィール'],
        '/dashboard/consultation': ['トップ', ''],
        [`/dashboard/consultation/${uuid}/details`]: ['トップ', '']
    };
};
export const generateBreadcrumbLinks = (uuid: string, kind: string) => {
    return {
        '/dashboard/meeting': ['dashboard', 'meeting'],
        '/dashboard/user-management': ['dashboard', 'user-management'],
        '/dashboard/user-management/user-registration': ['dashboard', 'dashboard/user-management'],
        [`/dashboard/user-management/${uuid}/details/${kind}`]: ['dashboard', 'dashboard/user-management'],
        '/dashboard/josekin-meeting': ['dashboard', 'josekin-meeting'],
        [`/dashboard/josekin-meeting/${uuid}/details`]: ['dashboard', 'dashboard/josekin-meeting'],
        '/dashboard/hojokin-meeting': ['dashboard', 'hojokin-meeting'],
        [`/dashboard/hojokin-meeting/${uuid}/details`]: ['dashboard', 'dashboard/hojokin-meeting'],
        '/dashboard/wr/diagnose-management': ['dashboard', 'wr', 'diagnose-management'],
        '/dashboard/wr/meeting-management': ['dashboard', 'wr', 'meeting-management'],
        [`/dashboard/wr/meeting-management/${uuid}/details`]: ['dashboard', 'dashboard/wr/meeting-management'],
        '/dashboard/profile': ['dashboard', 'profile'],
        '/dashboard/consultation': ['dashboard', 'consultation'],
    };
};
