import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    deleteUserApi,
    getApplicationDetailListApi,
    getScheduleApi,
    getScheduleUpdateApi,
    getUserDetailListApi,
    getUserDetailsApi,
    getUserListApi,
    upadteStatusUserMeeting,
    updateScheduleApi,
    updateSpecialistScheduleApi,
} from 'api/user';
import { RootState } from 'app/store';

export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_USER_DETAIL_LIST = 'GET_USER_DETAIL_LIST';
export const GET_APPLICATION_DETAIL_LIST = 'GET_APPLICATION_DETAIL_LIST';
export const GET_UPDATED_SCHEDULES = 'GET_UPDATED_SCHEDULES';

export type MeetingParam = {
    uuid: string;
    type: string;
    page: number;
};

export type GetUserListRequest = {
    keyword?: string;
    page?: number;
};

type UserState = {
    success: boolean | null;
    loading: boolean;
    type: string;
    userList: UserList[];
    pagination: Pagination;
    userDelete: boolean | null;
    editCalendar: boolean | null;
    userCancel: boolean | null;
};

export type UserList = {
    available_service: string;
    consultation_content: number;
    email: string;
    id: number;
    office_name: string;
    permission: string;
    regulation_content: number;
    role: string;
    specialist_firstname: string;
    specialist_lastname: string;
    deleted: boolean;
    uuid: string;
};

export type UserDetailList = {
    uuid: string;
    meeting_created_date: string;
    status: number;
    corporation_user_company_name: string;
    funds_name: string[];
    kisoku_name: string;
    meeting_schedule: string;
    chukidan_zoom_account_id: string;
    read_status?: number;
};

export type ApplicationDetailListResponse = {
    uuid: string;
    meeting_created_date: string;
    status: number;
    corporation_user_company_name: string;
    funds_name: string[];
    kisoku_name: string;
    meeting_schedule: string;
    chukidan_zoom_account_id: string;
    chukidan_zoom_account_name: string;
    read_status?: number;
};

export type ApplicationDetailList = {
    uuid: string;
    meetingCreatedDate: string;
    status: number;
    corporationUserCompanyName: string;
    fundsName: string[];
    kisokuName: string;
    meetingSchedule: string;
    chukidanZoomAccountId: string;
    chukidanZoomAccountName: string;
    readStatus?: number;
}

export type UserDetailsById = {
    available_service: string;
    consultation_content: number;
    email: string;
    id: number;
    office_name: string;
    permission: string;
    regulation_content: number;
    role: string;
    specialist_firstname: string;
    specialist_lastname: string;
    uuid: string;
    deleted: boolean;
};

export type UpdateStatusUserData = {
    uuid: string;
    reason: string;
}

// Pagination Data Structure returned by API call
export type Pagination = {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

export type Schedules = {
    date: string,
    time_range: string,
    status: number,
    is_selected_specialist?: number,
    show?: boolean
}

export type ScheduleData = {
    uuid: string,
    schedules: Schedules[]
};

export type UpdateCalendarRequest = {
    uuid: string,
    request: {
        add: string[],
        delete: string[],
    }
}

export type UpdateSpecialistCalendarRequest = {
    request: {
        add: string[],
        delete: string[],
    }
}

export type CalendarListRequest = {
    uuid: string,
    request: {
        year_month: string,
    }
}

/**
 * GET user details by id
 **/
export const getUserDetails = createAsyncThunk('/user/userDetails/:uuid', async (uuid: string, {
    dispatch,
    rejectWithValue,
}) => {
    try {
        const response = await getUserDetailsApi(uuid);
        if (response.data.success === true) {
            dispatch(setUserDetails(response.data));
            return true;
        }
        return response.data;
    } catch (err) {
        return rejectWithValue(false);
    }
});

/**
 * GET user list data
 **/
export const getUserList = createAsyncThunk('user/getList', async (data: GetUserListRequest, {
    dispatch,
    rejectWithValue,
}) => {
    try {
        const response = await getUserListApi(data);
        if (response.data.success === true) {
            dispatch(setUserList(response.data));
            return true;
        }
        return response.data;
    } catch (err) {
        return rejectWithValue(false);
    }
});

/**
 * GET user detail list data
 */
export const getUserDetailList = createAsyncThunk('user/getDetailList', async (data: string, {
    getState,
    dispatch,
    rejectWithValue,
}) => {
    try {
        let uuid = (getState() as RootState).auth.uuid;
        const response = await getUserDetailListApi(uuid, data);
        if (response.data.success === true) {
            dispatch(setUserDetailList(response.data));
            return true;
        }
        return response.data;
    } catch (err) {
        return rejectWithValue(false);
    }
});

/**
 * GET application detail list data
 */
export const getApplicationDetailList = createAsyncThunk('user/getApplicationDetailList', async (data: MeetingParam, {
    dispatch,
    rejectWithValue,
}) => {
    try {
        const { uuid, type, page } = data;
        const response = await getApplicationDetailListApi(uuid, type, page);
        if (response.data.success === true) {
            dispatch(setApplicationDetailList(response.data));
            return true;
        }
        return response.data;
    } catch (err) {
        return rejectWithValue(false);
    }
});

/**
 * Update status application detail meeting
 */
export const updateStatusUserMeeting = createAsyncThunk('applications/:uuid/not-conducted', async (data: UpdateStatusUserData, { rejectWithValue }) => {
    try {
        const { uuid, reason } = data;
        const response = await upadteStatusUserMeeting({ uuid, reason });

        return response.data;
    } catch (err) {
        return rejectWithValue(false);
    }
});

/**
 * Delete user data
 **/
export const deleteUser = createAsyncThunk('user/:uuid', async (data: string, { dispatch, rejectWithValue }) => {
    try {
        const response = await deleteUserApi(data);

        dispatch(setDeleteUser(response.data));
        return response.data;
    } catch (err) {
        return rejectWithValue(false);
    }
});

export const getSchedules = createAsyncThunk('user/:uuid/schedules', async (data: CalendarListRequest, {
    dispatch,
    rejectWithValue,
}) => {
    try {
        const response = await getScheduleApi(data);
        if (response.data.success === true) {
            dispatch(setSchedules(response.data));
            return true;
        }
        return response.data;
    } catch (err) {
        return rejectWithValue(false);
    }
});

export const getSchedulesUpdate = createAsyncThunk('user/:uuid/schedules/update', async (data: CalendarListRequest, {
    dispatch,
    rejectWithValue,
}) => {
    try {
        const response = await getScheduleUpdateApi(data);
        if (response.data.success === true) {
            dispatch(setScheduleUpdate(response.data));
            return true;
        }
        return response.data;
    } catch (err) {
        return rejectWithValue(false);
    }
});

export const updateSchedule = createAsyncThunk('edit/:uuid/schedules', async (data: UpdateCalendarRequest, { rejectWithValue }) => {
    try {
        const response = await updateScheduleApi(data);
        if (response.data.success === true) {
            // dispatch(setSchedules(response.data));
            return true;
        }
        return response.data;
    } catch (err) {
        return rejectWithValue(false);
    }
});

export const updateSpecialistSchedule = createAsyncThunk(
    'specialist/schedules/update',
    async (data: UpdateSpecialistCalendarRequest, { rejectWithValue },
    ) => {
        try {
            const response = await updateSpecialistScheduleApi(data);
            if (response.data.success === true) {
                // dispatch(setSchedules(response.data));
                return true;
            }
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    });

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        success: false,
        loading: false,
        userList: [] as UserList[],
        userDetailList: [] as UserDetailList[],
        applicationDetailList: [] as ApplicationDetailList[],
        userDetailsById: {} as UserDetailsById,
        type: '',
        pagination: {} as Pagination,
        userDelete: null,
        schedules: [] as Schedules[],
        schedulesUpdate: [] as Schedules[],
        selectableFrom: '',
        selectableUntil: '',
        editCalendar: null,
        userCancel: null,
        selectedSchedules: [],
    },

    reducers: {
        setUserList: (state, { payload }) => {
            state.userList = payload.data;
            state.pagination.perPage = payload.per_page;
            state.pagination.currentPage = payload.current_page;
            state.pagination.lastPage = payload.last_page;
            state.pagination.total = payload.total;
        },
        setUserDetailList: (state, { payload }) => {
            state.userDetailList = payload.data || [];
            state.pagination.perPage = payload.per_page;
            state.pagination.currentPage = payload.current_page;
            state.pagination.lastPage = payload.last_page;
            state.pagination.total = payload.total;
        },
        setApplicationDetailList: (state, { payload }) => {
            const data = payload.data as ApplicationDetailListResponse[];
            state.applicationDetailList = data.map(applicationDetailList => ({
                uuid: applicationDetailList.uuid,
                meetingCreatedDate: applicationDetailList.meeting_created_date,
                status: applicationDetailList.status,
                corporationUserCompanyName: applicationDetailList.corporation_user_company_name,
                fundsName: applicationDetailList.funds_name,
                kisokuName: applicationDetailList.kisoku_name,
                meetingSchedule: applicationDetailList.meeting_schedule,
                chukidanZoomAccountId: applicationDetailList.chukidan_zoom_account_id,
                chukidanZoomAccountName: applicationDetailList.chukidan_zoom_account_name,
                readStatus: applicationDetailList.read_status,
            })) || [];
            state.pagination.perPage = payload.per_page;
            state.pagination.currentPage = payload.current_page;
            state.pagination.lastPage = payload.last_page;
            state.pagination.total = payload.total;
        },
        setUserDetails: (state, { payload }) => {
            state.userDetailsById = payload.data;
        },
        setDeleteUser: (state, { payload }) => {
            state.userDelete = payload.success;
        },
        setSchedules: (state, { payload }) => {
            state.schedules = payload.data.schedules ?? [];
            state.selectableFrom = payload.data.selectable_from;
            state.selectableUntil = payload.data.selectable_until;
        },
        setScheduleUpdate: (state, { payload }) => {
            state.schedulesUpdate = payload.data.schedules ?? [];
            state.selectableFrom = payload.data.selectable_from;
            state.selectableUntil = payload.data.selectable_until;
        },
        setSelectedSchedules: (state, { payload }) => {
            state.selectedSchedules = payload ?? [];
        },
        resetEditCalendar: (state) => {
            state.editCalendar = null;
        },
        resetCancelUser: (state) => {
            state.userCancel = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserList.pending, (state: UserState) => {
            state.type = GET_USER_LIST;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(getUserList.rejected, (state: UserState) => {
            state.type = GET_USER_LIST;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getUserList.fulfilled, (state: UserState) => {
            state.type = GET_USER_LIST;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(getUserDetailList.pending, (state: UserState) => {
            state.type = GET_USER_DETAIL_LIST;
            state.loading = true;
            state.success = false;
            state.pagination = {} as Pagination;
        });
        builder.addCase(getUserDetailList.rejected, (state: UserState) => {
            state.type = GET_USER_DETAIL_LIST;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getUserDetailList.fulfilled, (state: UserState) => {
            state.type = GET_USER_DETAIL_LIST;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(getApplicationDetailList.pending, (state: UserState) => {
            state.type = GET_APPLICATION_DETAIL_LIST;
            state.loading = true;
            state.success = false;
            state.pagination = {} as Pagination;
        });
        builder.addCase(getApplicationDetailList.rejected, (state: UserState) => {
            state.type = GET_APPLICATION_DETAIL_LIST;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getApplicationDetailList.fulfilled, (state: UserState) => {
            state.type = GET_APPLICATION_DETAIL_LIST;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(deleteUser.pending, (state: UserState) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(deleteUser.rejected, (state: UserState) => {
            state.loading = false;
            state.success = false;
            state.userDelete = false;
        });
        builder.addCase(deleteUser.fulfilled, (state: UserState) => {
            state.loading = false;
            state.success = true;
            state.userDelete = true;
        });
        builder.addCase(getSchedules.pending, (state: UserState) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(getSchedules.rejected, (state: UserState) => {
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getSchedules.fulfilled, (state: UserState) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(getSchedulesUpdate.pending, (state: UserState) => {
            state.type = GET_UPDATED_SCHEDULES;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(getSchedulesUpdate.rejected, (state: UserState) => {
            state.type = GET_UPDATED_SCHEDULES;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getSchedulesUpdate.fulfilled, (state: UserState) => {
            state.type = GET_UPDATED_SCHEDULES;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(updateSchedule.pending, (state: UserState) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(updateSchedule.rejected, (state: UserState) => {
            state.loading = false;
            state.success = false;
            state.editCalendar = false;
        });
        builder.addCase(updateSchedule.fulfilled, (state: UserState) => {
            state.loading = false;
            state.success = true;
            state.editCalendar = true;
        });
        builder.addCase(updateSpecialistSchedule.pending, (state: UserState) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(updateSpecialistSchedule.rejected, (state: UserState) => {
            state.loading = false;
            state.success = false;
            state.editCalendar = false;
        });
        builder.addCase(updateSpecialistSchedule.fulfilled, (state: UserState) => {
            state.loading = false;
            state.success = true;
            state.editCalendar = true;
        });
        builder.addCase(updateStatusUserMeeting.pending, (state: UserState) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(updateStatusUserMeeting.rejected, (state: UserState) => {
            state.loading = false;
            state.success = false;
            state.userCancel = false;
        });
        builder.addCase(updateStatusUserMeeting.fulfilled, (state: UserState) => {
            state.loading = false;
            state.success = true;
            state.userCancel = true;
        });
    },
});

export const {
    setUserList,
    setUserDetailList,
    setApplicationDetailList,
    setUserDetails,
    setDeleteUser,
    setSchedules,
    resetEditCalendar,
    setScheduleUpdate,
    setSelectedSchedules,
    resetCancelUser,
} = userSlice.actions;
export const selectUser = (state: RootState) => state.user;



