import React from 'react';
import { Specialist } from 'app/slice/policyDetailsSlice';

type Props = {
    specialist: Specialist;
};

const MeetingDetailsSubheader = (props: Props) => {
    const { specialist } = props;
    return (
        <div className='user-details-info'>
            <p className='desc mb-2'>
                <span>事務所名／氏名:</span> {specialist?.office_name} / {specialist?.name}
            </p>
            <p className='desc mb-0'>
                <span>権限:</span> {specialist?.permission} ({specialist?.available_service})
            </p>
        </div>
    );
};
export default MeetingDetailsSubheader;
