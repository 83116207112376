import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Modal } from 'antd';

type Props = {
    isVisible: boolean;
    title: string;
    description: string;
    onClose: () => void;
};

const FailCancelMeetingDetailsUnderSpecialistModal = (props: Props) => {
    const { isVisible, title, description, onClose } = props;
    const [form] = Form.useForm();

    return (
        <Modal className='user-list-delete-modal' width={800} visible={isVisible} footer={null} onCancel={onClose}>
            <div className='title-modal'>{title}</div>
            <Form form={form} layout='vertical'>
                <div className='cancel-info'>
                    <div className='text-center mw-100 cancel-info-description'>{description}</div>
                </div>

                <div className='form-buttons mt-4'>
                    <Button className='general-button cancel-button mx-auto' onClick={onClose}>
                        <span className='me-3'>
                          <CloseOutlined />
                        </span>
                        閉じる
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default FailCancelMeetingDetailsUnderSpecialistModal;
