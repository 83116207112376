import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPolicyDetailsApi } from "api/policyDetails";
import { RootState } from "app/store";

export const GET_POLICY_DETAILS = 'GET_APPLICATION_DETAILS';

export type GetPolicyDetailsRequest = {
    uuid: string;
    applicationUuid: string;
};
// Application Details State
type PolicyDetailsState = {
    success: boolean | null,
    loading: boolean,
    policyDetails: PolicyDetails,
    type: string | null
}

export type PolicyDetails = {
    uuid: string;
    person_in_charge: string | null;
    date_created: string;
    status: string;
    read_status: number;
    type: string;
    specialist_schedule_label: string;
    specialist: Specialist;
    company: Company;
    funds: Fund[];
    topics: {
        code: string;
        name: string;
    }[];
    meeting: Meeting;
    cancellation_reason: string | null;
    cancellation_date: string | null;
};

export type Specialist = {
    office_name: string;
    name: string;
    role: string;
    permission: string;
    available_service: string;
};

type Company = {
    name: string;
    address: string;
    industry: string;
    labor_regulations_compliant: number;
    allow_sharoushi: number;
    allow_shindanshi: number;
    insurances: Insurances[];
    person_in_charge: string;
    prefecture: string;
    employee_count_ranges: string;
    parttime_employee_count: number;
};

type Fund = {
    code: string;
    type: number;
    title: string;
    fund_requirements: FundRequirements[];
    application_remarks: string;
};

type FundRequirements = {
    title: string;
    requirements: {
        content: string;
    }[];
};

type Meeting = {
    zoom_id: string;
    join_url: string;
    daido_joins: number;
    password: string;
    emergency_contact_number: string | null;
    meeting_remarks: string;
    schedule: string;
    schedule_datetime: string;
    unimplemented_comments: string;
};

type Insurances = {
    code: string;
    name: string;
    created_at: string | null;
    updated_at: string | null;
    pivot: {
        company_id: number;
        insurance_plan_id: number;
    };
};

/**
 * GET Application details by uuid
 **/
export const getPolicyDetails = createAsyncThunk('/company/policy/details', async (uuid: string, { dispatch, rejectWithValue }) => {
    try {
        const response = await getPolicyDetailsApi(uuid);
        if (response.data.success === true) {
            dispatch(setPolicyDetails(response.data));
            return true;
        }
        return response.data;
    } catch (err) {
        return rejectWithValue(false);
    }
},
);

/**
 * Create Application Details Slice
 */
export const policyDetailsSlice = createSlice({
    name: 'policy',
    initialState: {
        success: false,
        loading: false,
        policyDetails: {} as PolicyDetails,
        type: '',
    } as PolicyDetailsState,
    reducers: {
        reset: (state: PolicyDetailsState) => {
            state.success = false;
            state.loading = true;
            state.policyDetails = {} as PolicyDetails;
            state.type = '';
        },
        setPolicyDetails: (state, { payload }) => {
            state.policyDetails = payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPolicyDetails.pending, (state: PolicyDetailsState) => {
            state.type = GET_POLICY_DETAILS;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(getPolicyDetails.rejected, (state: PolicyDetailsState) => {
            state.type = GET_POLICY_DETAILS;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getPolicyDetails.fulfilled, (state: PolicyDetailsState) => {
            state.type = GET_POLICY_DETAILS;
            state.loading = false;
            state.success = false;
        });
    },
});

export const { setPolicyDetails } = policyDetailsSlice.actions;
export const selectPolicyDetails = (state: RootState) => state.policyDetails;