import 'assets/styles/app.scss';
import 'moment/locale/ja';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { authSelector, fetchUserData } from 'app/slice/authSlice';
import { reset } from 'app/slice/forgotPasswordSlice';
import Loader from 'components/common/Loader';
import SuccessModal from 'components/common/SuccessModal';
import ForgotPasswordModal from 'components/landing/ForgotPasswordModal';
import MainLayout from 'components/MainLayout';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import UserRegistration from 'pages/dashboard/UserRegistration';
import HojokinMeetingDetails from 'pages/dashboard/HojokinMeetingDetails';
import JosekinMeetingDetails from 'pages/dashboard/JosekinMeetingDetails';
import KisokuMeetingDetails from 'pages/dashboard/KisokuMeetingDetails';

// somehow react lazy doesn't work well with HOC, need to find a work-around first
// typescript bug I guess
const Home = lazy(() => import('pages/Home'));
const ForgotPassword = lazy(() => import('pages/main/ForgotPassword'));
const ForgotPasswordSuccess = lazy(() => import('pages/main/ForgotPasswordSuccess'));
const DashboardLayout = lazy(() => import('pages/dashboard/DashboardLayout'));
const MeetingCalendar = lazy(() => import('pages/dashboard/MeetingCalendar'));
const UserManagement = lazy(() => import('pages/dashboard/UserManagement'));
const UserDetails = lazy(() => import('pages/dashboard/UserDetails'));
const HojokinMeetingList = lazy(() => import('pages/dashboard/HojokinMeetingList'));
const JosekiMeetingList = lazy(() => import('pages/dashboard/JosekiMeetingList'));
const Profile = lazy(() => import('pages/dashboard/Profile'));
const ConsultationListCard = lazy(() => import('pages/dashboard/ConsultationList'));
const ConsultationDetails = lazy(() => import('pages/dashboard/ConsultationDetails'));
const Forbidden = lazy(() => import('pages/main/Forbidden'));
const Diagnose = lazy(() => import('pages/dashboard/Diagnose'));
const DiagnoseManagement = lazy(() => import('pages/dashboard/DiagnoseManagement'));
const WorkRegulationMeetingManagement = lazy(() => import('pages/dashboard/KisokuMeetingList'));

export const ALLOWED_ROLES = ['chukidan', 'chukidan-soken', 'sharoushi', 'shindanshi'];

const App = () => {
    const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] = useState(false);
    const { token } = useAppSelector(authSelector);
    const dispatch = useAppDispatch();

    const onCloseForgotPasswordModal = () => {
        setIsForgotPasswordModalVisible(false);
        dispatch(reset());
    };

    const onOpenForgotPasswordModal = () => {
        setIsForgotPasswordModalVisible(true);
    };

    useEffect(() => {
        if (token) {
            dispatch(fetchUserData());
        }
    }, [token, dispatch]);

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader force={true} />}>
                <Routes>
                    <Route path='/' element={<MainLayout />}>
                        <Route index element={<Home onOpenForgotPasswordModal={onOpenForgotPasswordModal} />} />
                        <Route path='forgot-password/:token/success' element={<ForgotPasswordSuccess />} />
                        <Route path='forgot-password/:token' element={<ForgotPassword />} />
                    </Route>
                    <Route path='/dashboard' element={<DashboardLayout />}>
                        <Route path='meeting' element={<MeetingCalendar />} />
                        <Route path='user-management'>
                            <Route index element={<UserManagement />} />
                            <Route path=':uuid/details/:kind' element={<UserDetails />} />
                            <Route path=':uuid/details/:kind/:applicationUuid/details' element={<UserDetails />} />
                            <Route path='user-registration' element={<UserRegistration />} />
                        </Route>
                        <Route path='josekin-meeting'>
                            <Route index element={<JosekiMeetingList />} />
                            <Route path=':uuid/details' element={<JosekinMeetingDetails />} />
                        </Route>
                        <Route path='hojokin-meeting'>
                            <Route index element={<HojokinMeetingList />} />
                            <Route path=':uuid/details' element={<HojokinMeetingDetails />} />
                        </Route>
                        <Route path='profile' element={<Profile />} />
                        <Route path='consultation'>
                            <Route index element={<ConsultationListCard />} />
                            <Route path=':uuid/details' element={<ConsultationDetails />} />
                        </Route>
                        <Route path='wr'>
                            <Route path='diagnose-management'>
                                <Route index element={<DiagnoseManagement />} />
                                <Route path=':id' element={<Diagnose />} />
                            </Route>
                            <Route path='meeting-management'>
                                <Route index element={<WorkRegulationMeetingManagement />} />
                                <Route path=':uuid/details' element={<KisokuMeetingDetails />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route path='/forbidden' element={<Forbidden />} />
                </Routes>
            </Suspense>

            <ForgotPasswordModal isVisible={isForgotPasswordModalVisible} onClose={onCloseForgotPasswordModal} />
            <SuccessModal />
        </BrowserRouter>
    );
};

export default App;
