export enum InterviewStatuses {
    MATCHING = 'マッチング中',
    SCHEDULED = '面談予約済み',
    SELECTING_SPECIALIST = '専門家選択',
    NO_MATCH = 'マッチングなし',
    CANCELED = 'キャンセル',
    FINISHED = '面談済み',
    REQUESTING = '中企団相談窓口ご案内中',
    WAITING_CONF = '面談日程確認待ち'
}

export enum ConsultationStatuses {
    NEW_INQUIRY = '新規依頼',
    SCHEDULED = '面談予約済み',
    DECLINED = '辞退',
    DUE_DATE = '回答期限切れ',
    CANCELED = 'キャンセル',
    FINISHED = '面談済み',
    WAITING_CONF = '企業からの返事待ち'
}

export enum PolicyStatuses {
    WAITING_FOR_REPORT = '診断レポート待ち',
    REPORT_DOWNLOADABLE = 'レポート閲覧可能',
    CONSULTATION_POSSIBLE = '面談申込可能',
}

export enum WorkingRegulationMeetingStatuses {
    NEW_INQUIRY = '新規依頼',
    SCHEDULED = '面談予約済み',
    FINISHED = '面談済み',
    CANCELED = 'キャンセル',
    WAITING = '企業からの返事待ち',
    DUE_DATE = '回答期限切れ',
}

export enum ApplicationType {
    FUND = 1,
    POLICY = 2,
}

export enum NotificationStatusEnum {
    Scheduled = '「面談予約済み」',
    Finished = '「面談済み」',
    Skipped = '「未実施」',
    Cancelled = '「キャンセル」',
    Expired = '「期限切れ」'
}

export enum NotificationStatusClasses {
    Scheduled = 'notification-status-scheduled',
    Finished = 'notification-status-finished',
    Skipped = 'notification-status-skipped',
    Cancelled = 'notification-status-cancelled',
    Expired = 'notification-status-cancelled',
}

export enum MeetingCalendarStatus {
    JOSEKIN = 1,
    HOJOKIN = 2,
    KISOKU = 3,
}

export enum ScheduleTooltipStatuses {
    AVAILABLE = '候補日提示',
    CONFIRMED = '予約済み',
    NO_AVAILABLE = '未入力',
}

export enum JosekiStatuses {
    MATCHING = 'マッチング中',
    SCHEDULED = '面談予約済み',
    SELECTING_SPECIALIST = '専門家選択',
    CANCELED = 'キャンセル',
    FINISHED = '面談済み',
    PAST_DUE = '回答期限切れ',
    SKIPPED = '未実施',
}

export enum MeetingUserDetailStatuses {
    SUBMITTED = 0,
    CANCELLED = 1,
    MATCHED = 2,
    SELECTING = 3,
    SCHEDULED = 4,
    FINISHED = 5,
    NO_MATCH = 6,
    CHKD_WAITING = 7,
    CHKD_SELECTING = 8,
    PAST_DUE = 9,
    NON_ATTENDANCE = 10,
}

export enum MeetingUserDetailReadStatuses {
    UNREAD = 0,
    READ = 1,
}

export enum SpecialistKindEnum {
    JOSEKIN_MEETING = 'josekin-meeting',
    HOJOKIN_MEETING = 'hojokin-meeting',
    KISOKU_MEETING = 'kisoku-meeting',
    REGULATION_DIAGNOSTIC = 'regulation-diagnostic'
}

export enum GeneralMeeting {
    JOSEKIN = 1,
    HOJOKIN = 2,
    KISOKU = 3,
}

export enum ApplicationStatuses {
    INTERVIEW_SCHEDULED = '面談予約済み',
    INTERVIEWED = '面談済み',
    CANCELLED = 'キャンセル',
    ANSWER_EXPIRED = '回答期限切れ',
    NOT_IMPLEMENTED = '未実施',
}

export enum TimeRangeStatuses {
    AVAILABLE = -1,
    RESERVED = 1,
    NOT_AVAILABLE = 2,
    PRESENTED = 0,
}

export enum FundType {
    GRANT = 4,
    SUBSIDY = 3
}
