import React, { useEffect, useState } from 'react';
import { CopyOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, message, Row } from 'antd';
import { ApplicationStatuses } from 'enums';
import { PolicyDetails } from 'app/slice/policyDetailsSlice';
import { formatDate } from 'lib/utils';
import ConfirmResultModal from '../ConfirmResultModal';
import CancelMeetingDetailsUnderSpecialistModal from './CancelMeetingDetailsUnderSpecialistModal';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { resetCancelUser, selectUser } from 'app/slice/userSlice';
import FailCancelMeetingDetailsUnderSpecialistModal from './FailCancelMeetingDetailsUnderSpecialistModal';
import { authSelector } from 'app/slice/authSlice';
import hasPermission from 'config/permission';
import { Permission_meeting_detail_page_role } from 'config/constant';

type Props = {
    data: PolicyDetails;
    fundType: string;
};

const MeetingDetails = (props: Props) => {
    const { data, fundType } = props;
    const [messageApi, contextHolder] = message.useMessage();
    const [showTopics, setShowTopics] = useState(false);
    const dateCreated = data?.date_created;
    const formattedDateCreated = formatDate(dateCreated);
    const cancellationDate = data?.cancellation_date;
    const formattedCancellationDate = formatDate(cancellationDate);
    const dispatch = useAppDispatch();
    const { userCancel } = useAppSelector(selectUser);
    const { roleName } = useAppSelector(authSelector);
    const [isUpdateMeetingModalVisible, setUpdateMeetingModalVisible] = useState(false);
    const [isConfirmUpdateMeetingModalVisible, setIsConfirmCancelModalVisible] = useState(false);
    const [isConfirmFailModalVisible, setIsConfirmFailModalVisible] = useState(false);
    const onOpenUpdateMeeting = () => setUpdateMeetingModalVisible(true);
    const onCloseUpdateMeeting = () => setUpdateMeetingModalVisible(false);
    const handleCloseConfirmUpdateMeetingModal = () => setIsConfirmCancelModalVisible(false);

    const handleCloseCancelFailModal = () => setIsConfirmFailModalVisible(false);

    const handleOpenConfirmUpdateMeetingModal = () => {
        setUpdateMeetingModalVisible(!isUpdateMeetingModalVisible);
    };

    const toggleShowTopics = () => {
        setShowTopics(!showTopics);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(data?.meeting?.join_url || '').then(() => messageApi.info('URL has been copied to clipboard'));
    };

    useEffect(() => {
        if (userCancel === true) {
            setIsConfirmCancelModalVisible(!isConfirmUpdateMeetingModalVisible);
        } else if (userCancel === false) {
            setIsConfirmFailModalVisible(!isConfirmFailModalVisible);
        }
        dispatch(resetCancelUser());
    }, [dispatch, isConfirmFailModalVisible, isConfirmUpdateMeetingModalVisible, userCancel]);

    return (
        <>
            {contextHolder}
            <div className={'d-flex row justify-content-sm-end mt-4'}>
                <span>面談依頼日：{formattedDateCreated}</span>
            </div>

            <div className={'d-flex flex-column'}>
                <div className='mt-2 meeting-details-div-top'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            {fundType === 'policy' ? '面談対象規程' : '公的資金名称'}
                        </Col>

                        <Col span={18} className='meeting-details-grant-title'>
                            {/* can generate multiple fund titles for kouteki*/}
                            <div className={'d-flex flex-column '}>
                                {fundType === 'policy'
                                    ? data?.type
                                    : data?.funds?.map((fund, key) => (
                                          <div key={key} className={'d-flex flex-column meeting-details-fund-title'}>
                                              {fund.title}
                                          </div>
                                      ))}
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className='meeting-details-div'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            面談日時
                        </Col>

                        <Col span={18} className='meeting-details-date'>
                            {data?.meeting?.schedule_datetime}
                        </Col>
                    </Row>
                </div>

                <div className='meeting-details-div'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            ミーティングURL
                        </Col>

                        <Col span={18} className='meeting-details-value'>
                            <Row>
                                {data?.status === ApplicationStatuses.INTERVIEWED ? (
                                    <Col span={24}>本面談は終了いたしました</Col>
                                ) : data?.status === ApplicationStatuses.CANCELLED ? (
                                    <Col span={24}>本面談はキャンセルとなりました</Col>
                                ) : data?.status === ApplicationStatuses.NOT_IMPLEMENTED ? (
                                    <Col span={24}>ご予約のお時間にZoomへの入室がなかったため、本面談はキャンセルとなりました</Col>
                                ) : (
                                    data?.status === ApplicationStatuses.INTERVIEW_SCHEDULED && (
                                        <>
                                            <Col span={18}>
                                                <a className='link light-blue-3' href={data?.meeting?.join_url || ''} target='_blank' rel='noreferrer'>
                                                    {data?.meeting?.join_url}
                                                </a>
                                            </Col>
                                            <Col span={4} className='copy-clipboard' onClick={copyToClipboard}>
                                                <CopyOutlined /> <span className='text-decoration-underline'>URLをコピー</span>
                                            </Col>
                                        </>
                                    )
                                )}
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className='meeting-details-div'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            入室パスワード
                        </Col>
                        {data?.status === ApplicationStatuses.INTERVIEW_SCHEDULED ? (
                            <Col span={18} className='meeting-details-value'>
                                {data?.meeting?.password}
                            </Col>
                        ) : (
                            <Col span={18} className='meeting-details-value'>
                                ー
                            </Col>
                        )}
                    </Row>
                </div>

                <div className='meeting-details-div'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            ZOOM ID
                        </Col>
                        {data?.status === ApplicationStatuses.INTERVIEW_SCHEDULED ? (
                            <Col span={18} className='meeting-details-value'>
                                {data?.meeting?.zoom_id}
                            </Col>
                        ) : (
                            <Col span={18} className='meeting-details-value'>
                                ー
                            </Col>
                        )}
                    </Row>
                </div>

                <div className='meeting-details-div'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            面談のご案内
                        </Col>
                        <Col span={18} className='meeting-details-value'>
                            <div className={'d-flex flex-column'}>
                                <div className={'d-flex'}>
                                    <div>※</div>
                                    <div>本面談は「社会保険労務士」または「中小企業診断士」がご対応いたします。</div>
                                </div>
                                <div className={'d-flex'}>
                                    <div>※</div>
                                    <div>面談ルームへの入室は予約時刻の５分前から可能です。</div>
                                </div>
                                <div className={'d-flex'}>
                                    <div>※</div>
                                    <div>
                                        面談時間は予約時刻から３０分間です。３分を過ぎた場合は予告なく面談終了となる可能性がござい
                                        ます。予めご了承ください。
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className='meeting-details-div'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            大同生命担当者
                        </Col>

                        <Col span={18} className='meeting-details-value'>
                            {data?.meeting?.daido_joins ? '同席を希望する' : '同席を希望しない'}
                        </Col>
                    </Row>
                </div>
                <div className='meeting-details-background mt-4 mb-4'>
                    <div className={'d-flex row py-3 meeting-details-border'}>
                        <div className={'mx-3 w-25 meeting-details-discussion-title'}>
                            {fundType === 'policy' ? '特にご相談したいこと' : '回答項目'}
                        </div>
                        <div className={'d-flex row mx-3 w-50'}>
                            <div className={'d-flex'}>上記公的資金の診断の際に選択・チェックされた項目です。</div>
                        </div>
                        <div className={'d-flex row align-self-center w-25 justify-content-sm-end mx-4'}>
                            <Button
                                shape={'round'}
                                onClick={toggleShowTopics}
                                className='round-button meeting-details-accordion-button'
                                htmlType='button'
                            >
                                詳細を表示{showTopics ? <UpOutlined /> : <DownOutlined />}
                            </Button>
                        </div>
                    </div>
                    {showTopics && (
                        <div className={'d-flex flex-column mx-3 mb-4 meeting-details-requirements'}>
                            {fundType === 'policy' ? (
                                <div className={'d-flex row'}>
                                    <ul>
                                        {data?.topics?.map((tp, index) => (
                                            <li key={index}>{tp?.name}</li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                <div className={'d-flex flex-column'}>
                                    {/* Applicable to Josekin with basic criteria */}
                                    {data?.funds?.[0].type === 4 && (
                                        <div>
                                            {data?.funds?.[0]?.fund_requirements
                                                .filter((fund) => fund.title === '基本確認項目')
                                                .map((fr) => (
                                                    <div className={'d-flex flex-column'}>
                                                        <div className={'title my-2'}>{fr?.title}</div>
                                                        <ul>
                                                            {fr?.requirements?.map((r) => (
                                                                <li>{r?.content}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ))}
                                        </div>
                                    )}

                                    {/* Applicable to Hojokin criteria and Josekin additonal criteria. */}
                                    {data?.funds?.map((fund, fKey) => (
                                        <div key={fKey} className={'d-flex flex-column'}>
                                            {fund.fund_requirements
                                                .filter((fund) => fund.title !== '基本確認項目')
                                                .map((fr, frKey) => (
                                                    <div className={'d-flex flex-column'}>
                                                        <div key={frKey} className={'title my-2'}>
                                                            {fr?.title}
                                                        </div>
                                                        <ul>
                                                            {fr?.requirements?.map((r) => (
                                                                <li>{r?.content}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ))}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {/* Meeting details inputted by user on meeting schedule page. */}
                <div className={'d-flex flex-column border mb-4 meeting-details-border'}>
                    <div className={'pt-3 mx-3 w-25 meeting-details-remarks-title'}>ご相談内容</div>
                    <div className={'d-flex row py-3 mx-3'}>
                        {fundType === 'policy' ? data?.meeting?.meeting_remarks || 'ー' : data?.funds?.[0]?.application_remarks || 'ー'}
                    </div>
                </div>

                {/* Comment input by specialists when they change the meeting status to 未実施 */}
                {data?.status === ApplicationStatuses.NOT_IMPLEMENTED && (
                    <div className={'d-flex flex-column border mb-4 meeting-details-border'}>
                        <div className={'pt-3 mx-3 w-25 meeting-details-remarks-title'}>未実施コメント</div>
                        <div className={'d-flex row py-3 mx-3'}>{data?.meeting?.unimplemented_comments || 'ー'}</div>
                    </div>
                )}

                {/* Comment inputted by corp users when they cancelled the meeting. */}
                {data?.status === ApplicationStatuses.CANCELLED && (
                    <div className={'d-flex flex-column border mt-2 mb-2 meeting-details-border'}>
                        <div className={'d-flex row'}>
                            <div className={'d-flex pt-3 row mx-3 w-75 meeting-details-remarks-title'}>キャンセル理由</div>
                            <div className={'d-flex row align-self-center w-25 justify-content-sm-end mx-4'}>
                                <span>キャンセルした日：{formattedCancellationDate}</span>
                            </div>
                        </div>
                        <div className={'d-flex row py-3 mx-3'}>{data?.cancellation_reason || 'ー'}</div>
                    </div>
                )}

                {/* Show only when status is only scheduled */}
                {data?.status === ApplicationStatuses.INTERVIEW_SCHEDULED && (
                    <div className='meeting-details-action-buttons'>
                        <Row>
                            <Col span={12}>
                                <Button
                                    shape={'round'}
                                    className='round-button meeting-details-bottom-btn'
                                    htmlType='button'
                                    onClick={onOpenUpdateMeeting}
                                >
                                    面談を未実施にする
                                </Button>
                            </Col>
                            {hasPermission(roleName, Permission_meeting_detail_page_role.READ_STATUS_CHECK_BOX_VIEW) && (
                                <Col span={12} className='checkbox-label'>
                                    {/* checkbox will be checked if read, otherwise unchecked. */}
                                    <Checkbox checked={data?.read_status === 1} disabled>
                                        専門家確認済み
                                    </Checkbox>
                                </Col>
                            )}
                        </Row>
                    </div>
                )}
            </div>
            <CancelMeetingDetailsUnderSpecialistModal
                isVisible={isUpdateMeetingModalVisible}
                uuid={data.uuid}
                fundType={fundType}
                applicationUuid={data.uuid}
                onClickConfirm={handleOpenConfirmUpdateMeetingModal}
                onClose={onCloseUpdateMeeting}
            />
            <ConfirmResultModal
                isVisible={isConfirmUpdateMeetingModalVisible}
                title={'面談ステータスを未実施に変更しました'}
                description={'自動でこの画面は閉じます。'}
                onClose={handleCloseConfirmUpdateMeetingModal}
            />
            <FailCancelMeetingDetailsUnderSpecialistModal
                isVisible={isConfirmFailModalVisible}
                title={'面接ステータスを未開催に変更することはできません'}
                description={''}
                onClose={handleCloseCancelFailModal}
            />
        </>
    );
};
export default MeetingDetails;
