import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getPolicyDetails, selectPolicyDetails } from 'app/slice/policyDetailsSlice';
import MeetingDetailsHeader from 'components/common/meeting_detail_page/MeetingDetailsHeader';
import MeetingDetailsSubheader from 'components/common/meeting_detail_page/MeetingDetailsSubheader';
import MeetingDetails from 'components/common/meeting_detail_page/MeetingDetails';
import { scrollToTop } from 'lib/utils';

const JosekinMeetingDetails = () => {
    const { policyDetails } = useAppSelector(selectPolicyDetails);
    const { uuid } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const fundType = 'grant';

    // Get josekin meeting details based on application uuid.
    useEffect(() => {
        if (uuid) {
            dispatch(getPolicyDetails(uuid));
        }
    }, [uuid]);

    // Scroll to top on navigate
    useEffect(() => {
        scrollToTop('smooth');
    }, [navigate]);

    return (
        <>
            <MeetingDetailsHeader applicationStatus={policyDetails.status} />
            <MeetingDetailsSubheader specialist={policyDetails.specialist} />
            <MeetingDetails data={policyDetails} fundType={fundType} />
        </>
    );
};

export default JosekinMeetingDetails;
