import React from 'react';
import { Button, Col, Modal, Row } from 'antd';

type Props = {
    isVisible: boolean,
    onClose: () => void,
    title: string,
    icon: string,
    text: string,
    showCancelButton: boolean,
    cancelButtonText?: string,
    showConfirmButton: boolean,
    confirmButtonText?: string,
    confirmOnClick?: () => void,
    cancelOnClick?: () => void,
    cancelDisabled?: boolean,
    confirmLoading?: boolean,
}

const CustomModal = (props: Props) => {
    const {
        icon,
        isVisible,
        onClose,
        text,
        title,
        showCancelButton,
        cancelButtonText,
        showConfirmButton,
        confirmButtonText,
        cancelOnClick,
        confirmOnClick,
        cancelDisabled,
        confirmLoading,
    } = props;

    return (
        <Modal className='custom-confirmation-modal' visible={isVisible} onCancel={onClose} footer={null}>
            <div className='title'>{title}</div>
            <img src={icon} alt='' className='mx-auto d-block' />
            <div className='p-4 text-center'>
                {text}
            </div>
            <div className='text-center'>
                <Row justify={'center'} gutter={[10, 10]}>
                    {showCancelButton && (
                        <Col xs={24} md={12}>
                            <Button className='mx-1' block
                                    onClick={cancelOnClick}
                                    disabled={cancelDisabled}
                            >{cancelButtonText}</Button>
                        </Col>
                    )}
                    {showConfirmButton && (
                        <Col xs={24} md={12}>
                            <Button className='mx-1' type='primary' block
                                    onClick={confirmOnClick}
                                    loading={confirmLoading}
                            >{confirmButtonText}</Button>
                        </Col>
                    )}
                </Row>
            </div>
        </Modal>
    );
};

export default CustomModal;
