import { UserRegistrationData } from 'app/slice/userRegistrationSlice';
import { getAxiosClient, getAxiosClientWithToken } from './index';
import { RegistrationFieldData } from 'app/slice/registrationSlice';
import {
    CalendarListRequest,
    GetUserListRequest,
    UpdateCalendarRequest,
    UpdateSpecialistCalendarRequest,
    UpdateStatusUserData,
} from 'app/slice/userSlice';
import qs from 'qs';
import { ProfileBasicData, ProfileFieldData } from 'types';
import { UpdateUserData } from 'app/slice/userUpdateSlice';

export const registering = (url: string, data: RegistrationFieldData) => {
    const restClient = getAxiosClient();
    return restClient.patch(url, qs.stringify(data), {
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
    });
};

export const fetchUserRegistrationData = (uuid: string, expires: string, signature: string) => {
    const restClient = getAxiosClient();
    return restClient.get('/company/register/' + uuid, { params: { expires: expires, signature: signature } });
};

export const getUserData = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/user');
};

/**
 * Function to call API and register new user
 **/
export const registerNewUserApi = (request: UserRegistrationData) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/chukidan/user`, request);
};

/**
 * Function to call API and get user details by id
 **/
export const getUserDetailsApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/chukidan/user/${uuid}`);
};

/**
 * Function to call API and update user details by id
 **/
export const updateUserDetailsApi = (request: UpdateUserData) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/chukidan/user/edit/${request.uuid}`, request);
};

/**
 * Function to call API and get a list of Company Policy related Applications
 **/
export const getUserListApi = (request: GetUserListRequest) => {
    const restClient = getAxiosClientWithToken();
    // Remove keywords key if empty
    if (request.keyword === undefined || request.keyword === '') {
        delete request['keyword'];
    }

    // Remove per_page key if empty or if it has an invalid value
    if (request.page === undefined || request.page <= 0) {
        delete request['page'];
    }

    return restClient.get(`/chukidan/users`, { params: request });
};

export const getUserDetailListApi = (uuid: string, kind: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/chukidan/user/` + uuid + `/meetings/` + kind);
}

export const getApplicationDetailListApi = (uuid: string, kind: string, page: number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/chukidan/user/` + uuid + `/meetings/` + kind, { params: { page } });
}

export const upadteStatusUserMeeting = (request: UpdateStatusUserData) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/chukidan/applications/` + request?.uuid + `/not-conducted`, { reason: request?.reason });
}

export const deleteUserApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete('/chukidan/user/' + uuid);
}

export const getProfile = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/company/users/' + uuid);
};

export const saveProfile = (uuid: string, data: ProfileFieldData) => {
    const restClient = getAxiosClientWithToken();

    return restClient.put('/company/users/' + uuid, qs.stringify(data), {
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
    });
};

export const saveBasicProfile = (uuid: string, data: ProfileBasicData) => {
    const restClient = getAxiosClientWithToken();

    return restClient.put('/specialist/users/basic', qs.stringify(data), {
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
    });
};

export const getScheduleApi = (data: CalendarListRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/chukidan/user/${data.uuid}/schedule`, { params: data.request });
};

export const getScheduleUpdateApi = (data: CalendarListRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/chukidan/user/all/${data.uuid}/schedule`, { params: data.request });
};

export const updateScheduleApi = (data: UpdateCalendarRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/chukidan/user/edit/${data.uuid}/schedule`, data.request);
};

export const updateSpecialistScheduleApi = (data: UpdateSpecialistCalendarRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch('/chukidan/schedules', data.request);
};