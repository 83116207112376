import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { registerNewUserApi } from 'api/user';
import { RootState } from 'app/store';

export const APPLICATION_CREATION_ERROR = 'APPLICATION_CREATION_ERROR';

//new registration
export type UserRegistrationState = {
    success: boolean | null;
    loading: boolean;
    type: string;
    error: boolean,
};

export type UserRegistrationData = {
    email: string,
    office_name: string,
    lastname: string,
    firstname: string,
    password: string,
    role_id: number,
    responsibility: []
}

/**
 * POST user registration data
 **/
export const registerNewUser = createAsyncThunk('registration/newUserData', async (data: UserRegistrationData, { rejectWithValue }) => {
    try {
        const response = await registerNewUserApi(data);
        if (response.data.success === true) {
            return true;
        }
        return response.data;
    } catch (err) {
        return rejectWithValue(false);
    }
});

export const userRegistrationSlice = createSlice({
    name: 'registration',
    initialState: {
        success: false,
        loading: false,
        type: '',
        error: false,
    } as UserRegistrationState,

    reducers: {
        reset: (state: UserRegistrationState) => {
            state.success = false;
            state.loading = false;
            state.type = '';
            state.error = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(registerNewUser.pending, (state: UserRegistrationState) => {
            state.type = APPLICATION_CREATION_ERROR;
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(registerNewUser.rejected, (state: UserRegistrationState) => {
            state.type = APPLICATION_CREATION_ERROR;
            state.loading = false;
            state.success = false;
            state.error = true;
        });
        builder.addCase(registerNewUser.fulfilled, (state: UserRegistrationState) => {
            state.type = APPLICATION_CREATION_ERROR;
            state.loading = false;
            state.success = true;
            state.error = false;
        });

    },
});


export const { reset } = userRegistrationSlice.actions;
export const userRegistration = (state: RootState) => state.userRegistration;