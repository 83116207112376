import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { generalMeetingApi, generalMeetingStatusApi } from 'api/meeting';
import { RootState } from 'app/store';

export type GeneralMeetingParam = {
    keywords?: string;
    page?: number;
    status?: number;
    type?: string | number;
};

export type Pagination = {
    count: number;
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

export type GeneralMeetingStatus = {
    code: number;
    name: string;
};

type Insurances = {
    code: string;
    name: string;
    createdAt: string | null;
    updatedAt: string | null;
    pivot: {
        companyId: number;
        insurancePlanId: number;
    };
};

type Company = {
    name: string;
    address: string;
    industry: string;
    laborRegulationsCompliant: number;
    allowSharoushi: number;
    allowShindanshi: number;
    insurances: Insurances[];
    personInCharge: string;
    prefecture: string;
    employeeCountRanges: string;
    parttimeEmployeeCount: number;
};

type FundRequirements = {
    title: string;
    requirements: {
        content: string;
    }[];
};

type Fund = {
    code: string;
    type: number;
    title: string;
    fundRequirements: FundRequirements[];
    applicationRemarks: string;
};

type Meeting = {
    zoom_id: string;
    zoom_account_name: string;
    join_url: string;
    daidoJoins: number;
    password: string;
    emergencyContactNumber: string | null;
    meetingRemarks: string;
    schedule: string;
};

export type Application = {
    uuid: string;
    personInCharge: string | null;
    dateCreated: string;
    status: string;
    readStatus: number;
    type: string | null;
    specialistScheduleLabel: string;
    specialistName: string;
    company: Company;
    funds: Fund[];
    topics: {
        code: string;
        name: string;
    }[];
    meeting: Meeting;
    cancellationReason: string | null;
    cancellationDate: string | null;
};

export type ApplicationResponse = {
    applications: {
        uuid: string;
        person_in_charge: string | null;
        date_created: string;
        status: string;
        read_status: number;
        type: string | null;
        specialist_schedule_label: string;
        company: {
            name: string;
            address: string;
            industry: string;
            labor_regulations_compliant: number;
            allow_sharoushi: number;
            allow_shindanshi: number;
            insurances: {
                code: string;
                name: string;
                created_at: string | null;
                updated_at: string | null;
                pivot: {
                    company_id: number;
                    insurance_plan_id: number;
                };
            }[];
            person_in_charge: string;
            prefecture: string;
            employee_count_ranges: string;
            parttime_employee_count: number;
        };
        funds: [
            {
                code: string;
                type: number;
                title: string;
                fund_requirements: FundRequirements[];
                application_remarks: string;
            }
        ];
        topics: {
            code: string;
            name: string;
        }[];
        specialist: {
            name: string;
        };
        meeting: {
            zoom_id: string;
            zoom_account_name: string;
            join_url: string;
            daido_joins: number;
            password: string;
            emergency_contact_number: string | null;
            meeting_remarks: string;
            schedule: string;
            schedule_datetime: string;
        };
        cancellation_reason: string | null;
        cancellation_date: string | null;
    }[];
    total_count: number;
};

export const getGeneralMeeting = createAsyncThunk('/specialist/policies/applications', async (data: GeneralMeetingParam, { dispatch }) => {
    try {
        const response = await generalMeetingApi(data);
        dispatch(setGeneralMeeting(response.data));
        return response.data;
    } catch (err) {
        return false;
    }
});

export const getGeneralStatusTypes = createAsyncThunk('/specialist/policies/applications/statuses', async (data, { dispatch }) => {
    try {
        const response = await generalMeetingStatusApi();
        dispatch(setGeneralMeetingStatusTypes(response.data));
        return response.data;
    } catch (err) {
        return false;
    }
});

export const generalMeetingSlice = createSlice({
    name: 'policyApplicationList',
    initialState: {
        loading: true,
        generalStatus: [] as GeneralMeetingStatus[],
        application: [] as Application[],
        applicationCount: 0,
        pagination: {} as Pagination,
    },
    reducers: {
        setGeneralMeeting: (state, { payload }) => {
            const { applications, total_count } = payload.data as ApplicationResponse;
            state.application = applications.map((data) => ({
                uuid: data.uuid,
                personInCharge: data.person_in_charge,
                dateCreated: data.date_created,
                status: data.status,
                readStatus: data.read_status,
                type: data.type,
                specialistScheduleLabel: data.specialist_schedule_label,
                specialistName: data.specialist?.name,
                company: data.company && {
                    name: data.company.name,
                    address: data.company.address,
                    industry: data.company.industry,
                    laborRegulationsCompliant: data.company.labor_regulations_compliant,
                    allowSharoushi: data.company.allow_sharoushi,
                    allowShindanshi: data.company.allow_shindanshi,
                    insurances:
                        data.company.insurances.map((insurance) => ({
                            code: insurance.code,
                            name: insurance.name,
                            createdAt: insurance.created_at,
                            updatedAt: insurance.updated_at,
                            pivot: {
                                companyId: insurance.pivot.company_id,
                                insurancePlanId: insurance.pivot.insurance_plan_id,
                            },
                        })) ?? [],
                    personInCharge: data.company.person_in_charge,
                    prefecture: data.company.prefecture,
                    employeeCountRanges: data.company.employee_count_ranges,
                    parttimeEmployeeCount: data.company.parttime_employee_count,
                },
                funds:
                    data.funds.map((fund) => ({
                        code: fund.code,
                        type: fund.type,
                        title: fund.title,
                        fundRequirements: fund.fund_requirements.map((requirement) => ({
                            title: requirement.title,
                            requirements: requirement.requirements.map((req) => ({ content: req.content })),
                        })),
                        applicationRemarks: fund.application_remarks,
                    })) ?? [],
                topics:
                    data.topics.map((topic) => ({
                        code: topic.code,
                        name: topic.name,
                    })) ?? [],
                meeting: {
                    zoom_id: data.meeting.zoom_id,
                    zoom_account_name: data.meeting.zoom_account_name,
                    join_url: data.meeting.join_url,
                    daidoJoins: data.meeting.daido_joins,
                    password: data.meeting.password,
                    emergencyContactNumber: data.meeting.emergency_contact_number,
                    meetingRemarks: data.meeting.meeting_remarks,
                    schedule: data.meeting.schedule_datetime,
                },
                cancellationReason: data.cancellation_reason,
                cancellationDate: data.cancellation_date,
            }));
            state.applicationCount = total_count;
            state.pagination = payload.pagination;
            state.pagination.perPage = payload.pagination.per_page;
            state.pagination.currentPage = payload.pagination.current_page;
            state.pagination.lastPage = payload.pagination.last_page;
        },
        setGeneralMeetingStatusTypes: (state, { payload }) => {
            state.generalStatus = payload.data;
        },
        resetGeneralMeeting: (state) => {
            state.loading = true;
            state.application = [];
            state.pagination = {} as Pagination;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getGeneralMeeting.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getGeneralMeeting.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getGeneralMeeting.fulfilled, (state) => {
            state.loading = false;
        });
    },
});

export const { setGeneralMeeting, setGeneralMeetingStatusTypes, resetGeneralMeeting } = generalMeetingSlice.actions;
export const selectGeneralMeeting = (state: RootState) => state.generalMeeting;
