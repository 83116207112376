import { CloseOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import { Rule } from "antd/lib/form";
import { useAppDispatch } from "app/hooks";
import { getPolicyDetails } from "app/slice/policyDetailsSlice";
import { updateStatusUserMeeting } from "app/slice/userSlice";

type Props = {
    uuid?: string;
    isVisible: boolean;
    fundType: string;
    applicationUuid?: string;
    onClose: () => void;
    onClickConfirm: () => void;
    handleReasonCancelInput?: (value: string) => void;
};

const reasonRule: Rule[] = [
  { required: true, message: '未実施にする理由をご入力ください。' },
];

const CancelMeetingDetailsUnderSpecialistModal = (props: Props) => {
  const { uuid, isVisible, fundType, applicationUuid, onClose, onClickConfirm } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const onSubmit = async (values: { reason: string }) => {
    dispatch(updateStatusUserMeeting({ uuid: applicationUuid!, reason: values.reason }));

    // Make sure to update application list
    dispatch(getPolicyDetails(uuid!));
    onClickConfirm();
  };

  return (
      <Modal className='meeting-details-modal' width={800} visible={isVisible} footer={null} onCancel={onClose}>
          <div className='title-modal'>面談ステータスを未実施に変更しますか？</div>
          <div className='subtitle-modal'>未実施にする理由をご入力ください。（必須）</div>
          <Form form={form} onFinish={onSubmit} layout='vertical'>
              <Form.Item name='reason' label='' rules={reasonRule}>
                  <Input.TextArea rows={6} placeholder='キャンセルする理由をご入力ください。' maxLength={500} />
              </Form.Item>
              <div className='form-buttons'>
                  <Button className='general-button cancel-button me-4' onClick={onClose}>
                      <span className='me-3'><CloseOutlined /></span>
                      閉じる
                  </Button>
                  <Button className='general-button submit-button' htmlType='submit'>
                      確認画面に進む
                  </Button>
              </div>
          </Form>
      </Modal>
  );

};

export default CancelMeetingDetailsUnderSpecialistModal;