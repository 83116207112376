export const Roles = {
    CHUKIDAN: 'chukidan',
    CHUKIDAN_SOKEN: 'chukidan-soken',
    SHAROUSHI: 'sharoushi',
    SHINDANSHI: 'shindanshi',
};

export const Permission_role = {
    ACCOUNT_REGISTER_DELETE: [Roles.CHUKIDAN, Roles.CHUKIDAN_SOKEN],
    CALENDAR_VIEW: [Roles.CHUKIDAN, Roles.CHUKIDAN_SOKEN],
    SPECIALISTS: [Roles.CHUKIDAN, Roles.CHUKIDAN_SOKEN],
    JOSEKIN_SCHEDULE: [Roles.CHUKIDAN, Roles.CHUKIDAN_SOKEN, Roles.SHAROUSHI],
    HOJOKIN_SCHEDULE: [Roles.CHUKIDAN, Roles.SHINDANSHI],
    KISOKU_DIAGNOSIS: [Roles.CHUKIDAN, Roles.CHUKIDAN_SOKEN, Roles.SHAROUSHI],
    KISOKU_SCHEDULE: [Roles.CHUKIDAN, Roles.CHUKIDAN_SOKEN, Roles.SHAROUSHI],
};

export const Permission_sidebar_role = {
    USER_MANAGEMENT_SIDEBAR_VIEW: [Roles.CHUKIDAN, Roles.CHUKIDAN_SOKEN],
    JOSEKIN_SIDEBAR_VIEW: [Roles.CHUKIDAN, Roles.CHUKIDAN_SOKEN, Roles.SHAROUSHI],
    HOJOKIN_SIDEBAR_VIEW: [Roles.CHUKIDAN, Roles.SHINDANSHI],
    KISOKU_MENU_SIDEBAR_VIEW: [Roles.CHUKIDAN, Roles.CHUKIDAN_SOKEN, Roles.SHAROUSHI],
}

export const Permission_dashboard_role = {
    SPECIALIST_DASHBOARD_VIEW: [Roles.SHAROUSHI, Roles.SHINDANSHI],
}

export const Permission_meeting_detail_page_role = {
    READ_STATUS_CHECK_BOX_VIEW: [Roles.CHUKIDAN, Roles.CHUKIDAN_SOKEN],
}
