import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    addApplicationCommentApi,
    cancelApplicationApi,
    CancelApplicationRequest,
    getApplicationCommentsApi, getApplicationDetailsApi,
    getApplicationsApi,
    GetApplicationsRequest,
    getApplicationStatusTypesApi,
    getAvailableTimeSlotOnCurrentDate,
    getHolidaysApi, updatePersonInCharge,
} from 'api/application';
import { RootState } from 'app/store';
import { SelectedSchedules } from './meetingSlice';
import { Moment } from 'moment';
import { getErrorMessage } from 'api';
import { AxiosError } from 'axios';
import { ConsultationStatuses } from 'enums';

export type { GetApplicationsRequest } from 'api/application';

export const GET_APPLICATIONS = 'GET_APPLICATIONS';

type AvailableStartTime = {
    availableStartTime: string,
    availability: boolean
}

type AvailableEndTime = {
    availableEndTime: string,
    availability: boolean
}

type AvailableTimeResponse = {
    start: string,
    end: string,
    available: boolean
}

export type CommentResponse = {
    name: string,
    comment: string,
    created_at: string
}

type ApplicationType = {
    applications: Application[],
    applicationStatusTypes: ApplicationStatus[],
    pagination: Pagination,
    application: Application,
    schedule: Schedule,
    company: Company,
    funds: Funds[],
    availableStartTime: AvailableStartTime[],
    availableEndTime: AvailableEndTime[],
    holidays: string[],
    type: string,
    comments: CommentResponse[],
    loading: boolean,
    success: boolean,
}

export type FundRequirements = {
    title: string,
    requirements: Requirements[]
}

export type Requirements = {
    content: string
}

export type Funds = {
    code: string,
    title: string,
    type: number,
    fundRequirements: FundRequirements[],
    applicationRemarks: string
}

export type FundsResponse = {
    code: string,
    title: string,
    type: number,
    fund_requirements: FundRequirements[],
    application_remarks: string
}

type CompanyResponse = {
    name: string,
    address: string,
    industry: string,
    labor_regulations_compliant: boolean,
    insurances: CompanyInsurance[],
    allow_sharoushi: boolean,
    allow_shindanshi: boolean,
}

export type Schedule = {
    date: string;
    datetime: string;
    personInCharge: string;
    meetingURL: string;
    password: string;
};

type ScheduleResponse = {
    id: number,
    uuid: string,
    number: number,
    date: string,
    content: string
}

type ApplicationResponse = {
    uuid: string,
    status: `${ConsultationStatuses}`,
    schedule: string,
    schedule_datetime: string,
    daido_joins: boolean,
    is_deadline: boolean,
    join_url: string,
    password: string,
    funds: FundsResponse[],
    company: CompanyResponse,
    chukidan_schedules: ScheduleResponse[],
    specialist: Specialist,
    person_in_charge: string,
    type: string,
    emergency_contact_number: string,
}

export type Company = {
    name: string,
    address: string,
    industry: string,
    laborRegulationsCompliant: boolean,
    insurances: CompanyInsurance[],
    allowSharoushi: boolean,
    allowShindanshi: boolean,
    userUuid?: string
}

type CompanyInsurance = {
    code: string,
    name: string
}

type Specialist = {
    uuid: string,
    role: string,
    email: string,
    name: string,
    officeName: string
}

// Application Data Structure
export type Application = {
    uuid: string,
    status: `${ConsultationStatuses}`,
    schedule: string,
    scheduleDatetime: string,
    daidoJoins: boolean,
    isDeadline: boolean,
    joinUrl: string,
    password: string,
    personInCharge: string
    specialist: Specialist,
    funds: Funds[],
    company: Company,
    schedules: SelectedSchedules[],
    emergencyContactNumber: string,
}

// Application Status Data Structure, mainly for Filter function
export type ApplicationStatus = {
    code: string,
    name: string
}

// Pagination Data Structure returned by API call
export type Pagination = {
    count?: number,
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
}

export type UpdatePersonInChargeRequest = {
    personInCharge: string;
    uuid: string;
}

/**
 * GET applications list
 **/
export const getApplications = createAsyncThunk(
    '/specialist/applications',
    async (request: GetApplicationsRequest, { dispatch }) => {
        try {
            const response = await getApplicationsApi(request);
            const { data, pagination } = response.data;
            // transform snake_case to camel_case
            const applications = [] as Application[];
            data?.forEach((app: ApplicationResponse) => {
                const convertedData: Funds[] = [];
                app?.funds?.forEach((fund: FundsResponse) => {
                    convertedData.push({
                        code: fund?.code,
                        type: fund?.type,
                        title: fund?.title,
                        fundRequirements: fund?.fund_requirements,
                        applicationRemarks: fund?.application_remarks,
                    });
                });

                applications?.push({
                    uuid: app?.uuid,
                    status: app?.status,
                    isDeadline: app?.is_deadline,
                    scheduleDatetime: app?.schedule_datetime,
                    joinUrl: app?.join_url,
                    password: app?.password,
                    daidoJoins: app?.daido_joins,
                    schedule: app?.schedule,
                    company: {
                        name: app?.company?.name,
                        address: app.company.address,
                        industry: app.company.industry,
                        laborRegulationsCompliant: app.company.labor_regulations_compliant,
                        allowSharoushi: app.company.allow_sharoushi,
                        allowShindanshi: app.company.allow_shindanshi,
                        insurances: app.company.insurances,
                    },
                    personInCharge: app?.person_in_charge,
                    funds: convertedData,
                    specialist: app?.specialist,
                    schedules: app?.chukidan_schedules,
                    emergencyContactNumber: app?.emergency_contact_number,
                });
            });
            dispatch(setApplications({ applications, pagination }));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * GET application details
 **/
export const getApplicationDetails = createAsyncThunk(
    '/specialist/applicationDetails',
    async (uuid: string, { dispatch, rejectWithValue }) => {
        try {
            const response = await getApplicationDetailsApi(uuid);
            const { data, success } = response.data;
            if (success) {
                dispatch(setApplication(data));
            }
            return response.data;
        } catch (err: unknown) {
            return rejectWithValue(getErrorMessage(err as AxiosError))
        }
    },
);

/**
 * GET application statuses list
 **/
export const getApplicationStatusTypes = createAsyncThunk(
    '/specialist/application/statuses',
    async (data, { dispatch }) => {
        try {
            const response = await getApplicationStatusTypesApi();
            dispatch(setApplicationStatusTypes(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

export type AddCommentType = {
    uuid: string,
    request: {
        comment: string
    }
}

/**
 * GET application statuses list
 **/
 export const addApplicationComment = createAsyncThunk(
    '/specialist/application/addComment',
    async (data : AddCommentType) => {
        try {
            const response = await addApplicationCommentApi(data);
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * GET application statuses list
 **/
 export const getApplicationComments = createAsyncThunk(
    '/specialist/application/getComments',
    async (uuid: string, { dispatch }) => {
        try {
            const response = await getApplicationCommentsApi(uuid);
            dispatch(setApplicationComments(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * UPDATE application status to cancel
 **/
export const cancelApplication = createAsyncThunk(
    '/specialist/applications',
    async (data: CancelApplicationRequest) => {
        try {
            const response = await cancelApplicationApi(data);
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

export const getAvailableTimeSlot = createAsyncThunk(
    '/specialist/getAvailableTimeSlot',
    async (date: Moment, { dispatch }) => {
        try {
            const response = await getAvailableTimeSlotOnCurrentDate(date);
            dispatch(setAvailableTimeSlot(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

export const getHolidays = createAsyncThunk(
    '/specialist/getHolidays',
    async (data, { dispatch }) => {
        try {
            const response = await getHolidaysApi();
            dispatch(setHolidays(response.data));
            return response.data;
        } catch (err) {
            dispatch(resetHolidays());
            return false;
        }
    },
);

export const personInChargeUpdate = createAsyncThunk(
    '/specialist/personInCharge',
    async (request: UpdatePersonInChargeRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await updatePersonInCharge(request);
            return response.data;
        } catch (err) {
            return rejectWithValue(getErrorMessage(err as AxiosError))
        }
    },
);

/**
 * Create Application Slice
 */
export const applicationSlice = createSlice({
    name: 'applications',
    initialState: {
        applications: [] as Application[],
        applicationStatusTypes: [] as ApplicationStatus[],
        pagination: {} as Pagination,
        application: {} as Application,
        schedule: {} as Schedule,
        availableStartTime: [] as AvailableStartTime[],
        availableEndTime: [] as AvailableEndTime[],
        comments: [] as CommentResponse[],
        holidays: [] as string[],
        type: '',
        loading: false,
        success: false,
    } as ApplicationType,
    reducers: {
        setApplications: (state, { payload }) => {
            state.applications = payload.applications;
            state.pagination = {
                count: payload.pagination.count,
                total: payload.pagination.total,
                perPage: payload.pagination.per_page,
                currentPage: payload.pagination.current_page,
                lastPage: payload.pagination.last_page,
            };
        },
        setApplicationStatusTypes: (state, { payload }) => {
            state.applicationStatusTypes = payload.data;
        },
        setApplicationComments: (state, { payload }) => {
            state.comments = payload.data;
        },
        // for consultation details
        setApplication: (state, { payload }) => {
            let funds = payload?.funds?.map((fund: FundsResponse) => ({
                code: fund?.code,
                type: fund?.type,
                title: fund?.title,
                fundRequirements: fund?.fund_requirements,
                applicationRemarks: fund?.application_remarks,
            }));
            state.application = {
                uuid: payload?.uuid,
                status: payload?.status,
                isDeadline: payload?.is_deadline,
                scheduleDatetime: payload?.schedule_datetime,
                joinUrl: payload?.join_url,
                password: payload?.password,
                daidoJoins: payload?.daido_joins,
                schedule: payload?.schedule,
                company: {
                    name: payload?.company?.name,
                    address: payload?.company?.address,
                    industry: payload?.company?.industry,
                    laborRegulationsCompliant: payload?.company?.labor_regulations_compliant,
                    allowSharoushi: payload?.company?.allow_sharoushi,
                    allowShindanshi: payload?.company?.allow_shindanshi,
                    insurances: payload?.company?.insurances,
                },
                specialist: payload?.specialist,
                personInCharge: payload?.person_in_charge,
                funds: funds,
                schedules: payload?.schedules,
                emergencyContactNumber: payload?.emergency_contact_number,
            } as Application;
        },
        setSchedule: (state, { payload }) => {
            state.schedule.datetime = payload?.scheduleDatetime;
            state.schedule.personInCharge = payload?.personInCharge;
            state.schedule.meetingURL = payload?.joinUrl;
            state.schedule.password = payload?.password;
        },
        setAvailableTimeSlot: (state, { payload }) => {
            const availableStartTime: AvailableStartTime[] = [];
            const availableEndTime: AvailableEndTime[] = [];
            payload.data?.forEach((item: AvailableTimeResponse) => {
                availableStartTime.push({
                    availableStartTime: item.start,
                    availability: item.available,
                });
                availableEndTime.push({
                    availableEndTime: item.end,
                    availability: item.available,
                });
            });
            state.availableStartTime = availableStartTime;
            state.availableEndTime = availableEndTime;
        },
        setHolidays: (state, { payload }) => {
            state.holidays = payload.data;
        },
        resetApplication: (state) => {
            state.application = {} as Application;
            state.schedule = {} as Schedule;
        },
        resetHolidays: (state) => {
            state.holidays = [] as string[];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getApplications.pending, (state) => {
            state.type = GET_APPLICATIONS;
            state.loading = true;
            state.success = false;
            state.applications = [];
        });
        builder.addCase(getApplications.rejected, (state) => {
            state.type = GET_APPLICATIONS;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getApplications.fulfilled, (state) => {
            state.type = GET_APPLICATIONS;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(addApplicationComment.pending, (state) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(addApplicationComment.rejected, (state) => {
            state.loading = false;
            state.success = false;
        });
        builder.addCase(addApplicationComment.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(personInChargeUpdate.pending, (state) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(personInChargeUpdate.rejected, (state) => {
            state.loading = false;
            state.success = false;
        });
        builder.addCase(personInChargeUpdate.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
        });
    },
});

export const {
    setApplications,
    setApplicationStatusTypes,
    setApplication,
    setHolidays,
    setSchedule,
    setAvailableTimeSlot,
    setApplicationComments,
    resetApplication,
    resetHolidays,
} = applicationSlice.actions;
export const selectApplications = (state: RootState) => state.applications;
