import { getAxiosClientWithToken } from './index';

/**
 * Function to call API and get a specific Application details that belongs to the current user
 **/
export const getPolicyDetailsApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/chukidan/application/${uuid}`);
};

