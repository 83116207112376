import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Modal, Radio, RadioChangeEvent, Row, Select, Skeleton, Space } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Rule } from 'antd/lib/form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { APPLICATION_CREATION_ERROR, registerNewUser, userRegistration, UserRegistrationData } from 'app/slice/userRegistrationSlice';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import CustomModal from 'components/common/CustomModal';
import errorIcon from 'assets/images/error.png';
import { authSelector } from 'app/slice/authSlice';
import { reset } from 'app/slice/userRegistrationSlice';

const supportedServices = [
    { label: '助成金相談', value: 1 },
    { label: '就業規則診断', value: 2 },
    { label: '就業規則相談', value: 3 },
    { label: '補助金相談', value: 4 },
];

const firmRules: Rule[] = [
    {
        required: true,
        message: '会社名を入力してください。',
    },
];

const lastnameRules: Rule[] = [
    {
        required: true,
        message: '姓をご記入ください。',
    },
];

const firstnameRules: Rule[] = [
    {
        required: true,
        message: '名前を入力してください。',
    },
];

const emailRules: Rule[] = [
    {
        required: true,
        message: 'メールアドレスをご入力ください。',
        type: 'email',
    },
];

const passwordRules: Rule[] = [
    {
        required: true,
        message: 'パスワードをご入力ください。',
    },
    {
        pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/,
        message: 'パスワードは英字と数字を含めてください。',
    },
];

const authorityRules: Rule[] = [
    {
        required: true,
        message: '権限を選択してください。',
    },
];

const servicesRules: Rule[] = [
    {
        required: true,
        message: 'サポートされているサービスを少なくとも 1 つチェックしてください。',
    },
];

const qualificationRules: Rule[] = [
    {
        required: true,
        message: '資格を選択してください。',
    },
];

const UserRegistration = () => {
    const { roleName } = useAppSelector(authSelector);
    const { success, loading, type, error } = useAppSelector(userRegistration);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isExpertAuthority, setExpertAuthority] = useState(false);
    const [newCheckedItems, setNewCheckItems] = useState<CheckboxValueType[]>([]);
    const [qualification, setQualification] = useState(5);
    const [isRoleDisable, setDisabledRole] = useState(false);
    const [isDisabledSharoushiService, setDisabledSharoushiService] = useState(true);
    const [isDisabledShindanshiService, setDisabledShindanshiService] = useState(true);
    const [isRegisterUser, setRegisterUser] = useState(false);
    const [form] = Form.useForm();
    const [isApplicationCreationErrorVisible, setIsApplicationCreationErrorVisible] = useState(false);
    const onCloseApplicationCreationErrorModal = () => {
        setIsApplicationCreationErrorVisible(false);
        form.resetFields(['email']);
    };

    useEffect(() => {
        // Only check for success after registration is completed.
        if (type === APPLICATION_CREATION_ERROR) {
            if (success && !error) {
                form.resetFields();
                setExpertAuthority(false);
                newUserPopup();
            } else if (!success && error) {
                setIsApplicationCreationErrorVisible(true);
            }
        }
    }, [type, success, error]);

    // Set data when role name is soken
    useEffect(() => {
        if (roleName === 'chukidan-soken') {
            setDisabledRole(true);
        }
    }, [roleName]);

    //handles select authority
    const onChangeAuthority = (value: number) => {
        if (value === 2) {
            setExpertAuthority(true);
        } else {
            setExpertAuthority(false);
            setQualification(5);
        }
        setNewCheckItems([]);
        setDisabledSharoushiService(true);
        setDisabledShindanshiService(true);
        form.resetFields(['qualification']);
        form.resetFields(['services']);
    };

    //handles qualification change in radio button
    const onChangeQualification = (e: RadioChangeEvent) => {
        if (e.target.value === 2) {
            setDisabledShindanshiService(true);
            setDisabledSharoushiService(false);
        } else {
            setDisabledSharoushiService(true);
            setDisabledShindanshiService(false);
        }
        setQualification(e.target.value);
        setNewCheckItems([]);
        form.resetFields(['services']);
    };

    //handles supported services checkbox value type
    const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
        setNewCheckItems(checkedValues);
    };

    //redirect button to user list page
    const redirectToUserListPage = () => {
        navigate(`/dashboard/user-management`);
    };

    const newUserPopup = () => {
        setRegisterUser(true);
        dispatch(reset());
        // Automatically close the modal after 2 seconds.
        setTimeout(() => {
            setRegisterUser(false);
            // Automatically redirect to user list page.
            redirectToUserListPage();
        }, 2000);
    };

    const onFormSubmit = () => {
        const formValues = form.getFieldsValue();

        const data = {
            email: formValues.email,
            office_name: formValues.officeName,
            lastname: formValues.lastname,
            firstname: formValues.firstname,
            password: formValues.password,
            role_id: qualification,
            // Only add 'responsibility' if qualification is not 5.
            ...(qualification !== 5 && { responsibility: newCheckedItems }),
        } as UserRegistrationData;

        dispatch(registerNewUser(data));
    };

    return (
        <>
            {loading ? (
                <Skeleton active />
            ) : (
                <div className='dashboard-user-registration'>
                    <div className='user-nav'>
                        <Row>
                            <Col span={12} className='title'>
                                ユーザー登録
                            </Col>
                            <Col span={12} className='return-btn-col'>
                                <Button className='return-btn' onClick={redirectToUserListPage}>
                                    <h3 className='return-btn-text'>
                                        <LeftOutlined className='btn-icon' />
                                        一覧へ戻る
                                    </h3>
                                </Button>
                            </Col>
                        </Row>
                    </div>

                    <div className='note'>
                        <p>項目は全て入力必須です。</p>
                    </div>

                    {/* All fields are required */}
                    <Form layout='vertical' onFinish={onFormSubmit} form={form}>
                        {/* company name */}
                        <div className='user-registration-div-top'>
                            <Row className='user-registration-row'>
                                <Col span={8} className='user-registration-label'>
                                    事務所名
                                </Col>

                                <Col span={12} className='user-registration-input'>
                                    <Form.Item name='officeName' rules={firmRules}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        {/* full name */}
                        <div className='user-registration-div'>
                            <Row className='user-registration-row'>
                                <Col span={8} className='user-registration-label'>
                                    氏名
                                </Col>

                                <Col span={12} className='user-registration-input'>
                                    <Row className='fullname-row'>
                                        <Col span={11}>
                                            <Form.Item name='lastname' rules={lastnameRules}>
                                                <Input placeholder='姓' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item name='firstname' rules={firstnameRules}>
                                                <Input placeholder='名' />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        {/* email address */}
                        <div className='user-registration-div'>
                            <Row className='user-registration-row'>
                                <Col span={8} className='user-registration-label'>
                                    メールアドレス
                                </Col>

                                <Col span={12} className='user-registration-input'>
                                    <Form.Item name='email' rules={emailRules}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        {/* password */}
                        <div className='user-registration-div'>
                            <Row className='user-registration-row'>
                                <Col span={8} className='user-registration-label'>
                                    パスワード
                                </Col>

                                <Col span={12} className='user-registration-input'>
                                    <Form.Item name='password' rules={passwordRules}>
                                        <Input type='password' />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        {/* select authority */}
                        <div className='user-registration-div'>
                            <Row className='user-registration-row'>
                                <Col span={8} className='user-registration-label'>
                                    権限
                                </Col>

                                <Col span={12} className='user-registration-input'>
                                    <Space wrap direction='vertical'>
                                        <Form.Item name='authority' rules={authorityRules}>
                                            <Select
                                                placeholder='選択してください'
                                                onChange={onChangeAuthority}
                                                options={[
                                                    { value: 1, label: '総研権限', disabled: isRoleDisable },
                                                    { value: 2, label: '専門家権限' },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Space>
                                </Col>
                            </Row>
                        </div>

                        {/* shows if select authority == 2 */}
                        {isExpertAuthority && (
                            <div>
                                {/* qualification */}
                                <div className='user-registration-div'>
                                    <Row className='user-registration-row'>
                                        <Col span={8} className='user-registration-label'>
                                            保有資格
                                        </Col>

                                        <Col span={12} className='user-registration-input'>
                                            <Form.Item name='qualification' rules={qualificationRules}>
                                                <Radio.Group value={qualification} onChange={onChangeQualification}>
                                                    <Radio value={2}>社労士</Radio>
                                                    <Radio value={3} disabled={isRoleDisable}>
                                                        診断士
                                                    </Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                                {/* supported services */}
                                <div className='user-registration-div'>
                                    <Row className='user-registration-row'>
                                        <Col span={8} className='user-registration-label'>
                                            対応業務
                                        </Col>

                                        <Col span={16} className='user-registration-input'>
                                            <Form.Item name='services' rules={servicesRules}>
                                                <Checkbox.Group
                                                    options={supportedServices.slice(0, 3)} // Only the first 3 checkboxes
                                                    value={newCheckedItems}
                                                    disabled={isDisabledSharoushiService}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </Form.Item>
                                            <Form.Item name='services' rules={servicesRules}>
                                                <Checkbox.Group
                                                    options={supportedServices.slice(3)} // last checkbox
                                                    value={newCheckedItems}
                                                    disabled={isDisabledShindanshiService}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        )}
                        {/* end */}

                        {/* Register btn */}
                        <div className='register-btn-div'>
                            <Col span={24} className='btn-col'>
                                <Button htmlType='submit' className='register-btn' loading={loading}>
                                    <h3 className='register-btn-text'>登録する</h3>
                                </Button>
                            </Col>
                        </div>
                    </Form>
                    {/* end */}
                </div>
            )}

            {/* User registration confirmation modal */}
            <Modal className='registration-confirmation-modal' visible={isRegisterUser} footer={null} closable={false}>
                <div className='title'>登録が完了しました</div>

                <div className='subtitle'>
                    <p>自動で一覧ページに戻ります。</p>
                </div>
            </Modal>

            {/*Modal when an application cannot be cancelled*/}
            <CustomModal
                isVisible={isApplicationCreationErrorVisible}
                onClose={onCloseApplicationCreationErrorModal}
                title='登録に失敗しました'
                icon={errorIcon}
                text='既にご使用されているメールアドレスが入力されています。'
                showCancelButton={false}
                showConfirmButton={true}
                confirmButtonText='閉じる'
                confirmOnClick={onCloseApplicationCreationErrorModal}
            />
        </>
    );
};

export default UserRegistration;
