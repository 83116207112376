import { getAxiosClientWithToken } from './index';
import { Moment } from 'moment';
import { AddCommentType, UpdatePersonInChargeRequest } from 'app/slice/applicationSlice';

export type GetApplicationsRequest = {
    keywords?: string;
    page?: number;
    status?: number;
    type?: string | number;
};

export type ScheduleConsultationRequest = {
    uuid: string,
    specialist_uuid: string,
    number: number,
    daido_joins: boolean,
}

export type CancelApplicationRequest = {
    reason: string,
    uuid: string
}

export type CancelConsultationRequest = {
    uuid: string,
    reason: string,
}

export type Label = {
    content: string,
    color: string,
}

export type Schedule = {
    uuid?: string,
    number: number,
    content: string,
}

export type SchedulePolicyConsultationRequest = {
    uuid: string,
    specialist_uuid: string,
    number: number,
}

/**
 * Function to call API and get a list of Applications belonging to the logged-in User
 **/
export const getApplicationsApi = (request: GetApplicationsRequest) => {
    const restClient = getAxiosClientWithToken();

    // Remove keywords key if empty
    if (request.keywords === undefined || request.keywords === '') {
        delete request['keywords'];
    }

    // Remove per_page key if empty or if it has an invalid value
    if (request.page === undefined || request.page <= 0) {
        delete request['page'];
    }

    // Remove status key if empty or invalid
    if (request.status === undefined || request.status === -1) {
        delete request.status;
    }

    // Remove status key if empty or invalid
    if (request.type === undefined || request.type <= 1) {
        delete request.type;
    }

    return restClient.get(`/chukidan/applications`, { params: request });
};

export const getApplicationDetailsApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/chukidan/application/${uuid}`);
};

/**
 * Function to call API and get a list of Application Status Types
 **/
export const getApplicationStatusTypesApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/chukidan/application/statuses');
};

export const addApplicationCommentApi = (data: AddCommentType) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/specialist/application/${data.uuid}/comment`, data.request);
};

export const getApplicationCommentsApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/specialist/application/${uuid}/comment`);
};

export const cancelApplicationApi = (data: CancelApplicationRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/specialist/application/${data.uuid}/cancel`, data);
};

/**
 * Function to call API and set the Consultation Schedule for a given Application
 */
export const cancelConsultationApi = (request: CancelConsultationRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/specialist/policies/applications/${request.uuid}/cancel`, request);
};

/**
 * Function to call API and get a list of Company Policy related Applications
 **/
export const getPolicyApplicationsApi = (request: GetApplicationsRequest) => {
    const restClient = getAxiosClientWithToken();

    // Remove keywords key if empty
    if (request.keywords === undefined || request.keywords === '') {
        delete request['keywords'];
    }

    // Remove per_page key if empty or if it has an invalid value
    if (request.page === undefined || request.page <= 0) {
        delete request['page'];
    }

    // Remove status key if empty or invalid
    if (request.status === undefined || request.status === -1) {
        delete request.status;
    }

    return restClient.get(`/chukidan/applications`, { params: request });
};

/**
 * Function to call API and get a list of Policy Application Status Types
 **/
export const getPolicyApplicationStatusTypesApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/chukidan/applications/statuses');
};

/**
 * Function to call API and get a specific Company Policy Application
 **/
export const getPolicyApplicationDetailsApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/chukidan/applications/${uuid}`);
};

/**
 * Function to call API and set the Consultation Schedule for a given Application
 */
export const schedulePolicyConsultationApi = (request: SchedulePolicyConsultationRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/specialist/policies/applications/${request.uuid}/schedule`, request);
};

export const getAvailableTimeSlotOnCurrentDate = (date: Moment) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/specialist/schedules/time-slots', { params: { date: date.format('YYYY-MM-DD') } });
};

export const getHolidaysApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/specialist/schedules/holidays');
};

export const updatePersonInCharge = (request: UpdatePersonInChargeRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/specialist/application/${request.uuid}/person-in-charge`, { person_in_charge: request.personInCharge });
}