import { Form, Modal } from 'antd';
import { useEffect } from 'react';

type Props = {
    isVisible: boolean;
    title: string;
    description: string;
    onClose: () => void;
};

const ConfirmResultModal = (props: Props) => {
    const { isVisible, title, description, onClose } = props;
    const [form] = Form.useForm();

    const onSubmit = () => {};

    useEffect(() => {
        if (isVisible) {
            const timer = setTimeout(() => {
                onClose();
            }, 2000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [isVisible]);

    return (
        <Modal className='user-list-delete-modal' width={800} visible={isVisible} footer={null} closable={false} onCancel={onClose}>
            <div className='title-modal'>{title}</div>
            <Form form={form} onFinish={onSubmit} layout='vertical'>
                <div className='cancel-info'>
                    <div className='text-center mw-100 cancel-info-description'>{description}</div>
                </div>
            </Form>
        </Modal>
    );
};

export default ConfirmResultModal;
